"use client";
import React from "react";
import { getPublicAssetPath } from "@/lib/asssetUtils";
import Image from "next/image";

interface TutorialCardProps {
  iconPath: string;
  iconAlt: string;
  title: string;
  description: string;
}

const TutorialCard: React.FC<TutorialCardProps> = ({
  iconPath,
  iconAlt,
  title,
  description,
}) => {
  return (
    <div className="p-6 bg-slate-50 rounded-lg shadow justify-start items-center gap-8 flex lg:max-w-[464px]">
      <Image
        src={getPublicAssetPath(iconPath)}
        width={84}
        height={84}
        alt={iconAlt}
      />
      <div className="flex flex-col gap-2">
        <h2 className="m-0 p-0 text-slate-800 text-lg leading-7 sm:text-xl lg:text-2xl lg:leading-loose font-semibold">
          {title}
        </h2>
        <p className="text-slate-600 text-xs font-normal leading-tight">
          {description}
        </p>
      </div>
    </div>
  );
};

const HowItWorks = () => {
  const tutorials: TutorialCardProps[] = [
    {
      iconPath: getPublicAssetPath("/assets/capture-icon.svg"),
      iconAlt: "capture",
      title: "Capture scoresheet",
      description:
        "Use your phone camera to capture the image of a chess scoresheet or multiple scoresheets. PDFs are also supported.",
    },
    {
      iconPath: getPublicAssetPath("/assets/analyze-icon.svg"),
      iconAlt: "capture",
      title: "AI OCR Analysis",
      description:
        "Our AI OCR and advanced chess AI algorithms analyze the image and extract the chess moves.",
    },
    {
      iconPath: getPublicAssetPath("/assets/display-icon.svg"),
      iconAlt: "capture",
      title: "Display Chessboard",
      description:
        "A chessboard is displayed with the PGN extracted from the scoresheet. Analyze, Review, Improve your game.",
    },
  ];
  return (
    <div className="flex flex-col lg:flex-row lg:justify-between mx-auto gap-4">
      {tutorials.map((tutorial, index) => (
        <TutorialCard
          key={index}
          iconPath={tutorial.iconPath}
          iconAlt={tutorial.iconAlt}
          title={tutorial.title}
          description={tutorial.description}
        />
      ))}
    </div>
  );
};

export default HowItWorks;
