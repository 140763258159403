"use client";
import React from "react";
import { Container } from "@/components/landing/Container";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export const Faq = () => {
  return (
    <Container className="!p-0 mt-16">
      <div className="w-full flex flex-col items-center ">
        {faqdata.map((item, index) => (
          <div key={item.question} className="mb-5 w-full max-w-[1196px]">
            <Disclosure>
              {({ open }) => (
                <>
                  <DisclosureButton className="p-[17px] w-full bg-white rounded-lg shadow border border-slate-200 text-slate-800 text-sm font-semibold leading-tight flex flex-col items-start gap-2.5 max-w-[1196px]">
                    <div className="flex justify-between self-stretch">
                      <span>{item.question}</span>
                      <ChevronDownIcon
                        className={`${
                          open ? "transform rotate-180" : ""
                        } w-5 h-5 text-indigo-500`}
                      />
                    </div>
                    <DisclosurePanel className="bg-white text-slate-600 text-sm font-normal leading-tight text-justify">
                      {item.answer}
                    </DisclosurePanel>
                  </DisclosureButton>
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>
    </Container>
  );
};

const faqdata = [
  {
    question: "Is xchess.ai suitable for beginners?",
    answer:
      "Yes! Our platform is designed to help players of all skill levels, from beginners to advanced. The AI adapts its analysis to your playing strength.",
  },
  {
    question: "Can I use xchess.ai on my mobile device?",
    answer:
      "Absolutely! xchess.ai is fully responsive and works on desktop, tablet, and mobile devices.",
  },
  {
    question: "How accurate is the OCR for converting scoresheets?",
    answer:
      "Our OCR technology boasts a high accuracy rate. However, you can always review and edit the results to ensure 100% accuracy.",
  },
  {
    question: "Is my data secure on xchess.ai?",
    answer:
      "We take data security seriously. All your games and personal information are encrypted and stored securely. We never share your data without your explicit permission.",
  },
];
